import helpers from './helpers';
import Config from './config';

var Url = {
  // http://stackoverflow.com/a/901144/1231563
  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i"),
    results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  },

  externalHost(link) {
    return link.hostname != location.hostname && link.protocol.indexOf('http') === 0;
  },

  extractUtms() {
    var utmArray = ['utm_source','utm_medium','utm_term','utm_content','utm_campaign'];
    var exists = false;
    for (var i = 0, l = utmArray.length; i < l; i++) {
      if (helpers.isset(this.getParameterByName(utmArray[i]))) {
        exists = true;
        break;
      }
    }
    if (exists) {
      var val;
      for (var i = 0, l = utmArray.length; i < l; i++) {
        val = Url.getParameterByName(utmArray[i]);
        if (helpers.isset(val)) {
          Config[utmArray[i]] = val;
        }
      }
    }
  },
}

export default Url;