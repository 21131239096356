export const UTM_CONSTANTS = {
  SOURCE: {
    OMNICHAT: 'omnichat'
  },
  MEDIUM: {
    WEBCHAT: 'webchat',
    REMARKETING_WEBCHAT: 'remarketing_webchat',
    WELCOME_NESSAGE: 'welcome_message'
  },
  CAMPAIGN: {
    WELCOME_MESSAGE: 'welcome_message',
    REMARKETING_WEBCHAT: 'remarketing_webchat',
    CHATBOT: 'chatbot'
  }
}
