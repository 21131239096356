import Config from './config';
import Cookie from './cookie';
import Session from './session';
import Browser from './browser';
import helpers from './helpers';
import Constants from './constants';
import EASYCHAT_CONFIG from '../config/config';

class Pixel {
  constructor(event, timestamp, optional) {
    Session.prepareSession();

    this.params = [];
    this.event = event;
    this.timestamp = timestamp;
    this.optional = helpers.optionalData(optional);
    this.invalidData = false;
    this.buildParams();
    this.buildEcommEventParams(optional);
    this.buildMemberMappingEventParams(optional);
    this.send();
  }

  buildParams() {
    const attr = this.getAttribute();
    for (var index in attr) {
      if (attr.hasOwnProperty(index)) {
        this.setParam(index, attr[index](index));
      }
    }
  }

  getAttribute() {
    return {
      ecid: () => Config.ecid, // user Id
      mid: () => Config.memberId,
      did: () => Config.deviceId, // gaClientId (91App only)
      sid: () => Config.shopId,
      p: () => Config.shopPlatform,
      t: () => Config.team,
      bh: () => this.event, // event being triggered
      utms: () => Cookie.getUtm('utm_source'), // get the utm source
      utmm: () => Cookie.getUtm('utm_medium'), // get the utm medium
      utmcp: () => Cookie.getUtm('utm_campaign'), // get the utm campaign
      utmt: () => Cookie.getUtm('utm_term'), // get the utm term
      utmct: () => Cookie.getUtm('utm_content'), // get the utm content
      said: () => Cookie.get(Constants.cookie.saleId), // get the sale user id
      tid: () => Cookie.get(Constants.cookie.trackId), // get the sale user id
      v: () => Config.version, // pixel version
      dl: () => Cookie.get(Constants.cookie.documentLocation) || window.location.href, // document location
      rl: () => document.referrer != window.location.href ? document.referrer:null, // referrer location
      dv: () => Browser.deviceType(), // device type
      ssid: () => Session.getSession(), // session info
      // ts: () => this.timestamp, // timestamp when event was triggered
      // de: () => document.characterSet, // document encoding
      // sr: () => window.screen.width + 'x' + window.screen.height, // screen resolution
      // vp: () => window.innerWidth + 'x' + window.innerHeight, // viewport size
      // cd: () => window.screen.colorDepth, // color depth
      // dt: () => document.title, // document title
      // bn: () => Browser.nameAndVersion(), // browser name and version number
      // md: () => Browser.isMobile(), // is a mobile device?
      // ua: () => Browser.userAgent(), // user agent
      // tz: () => (new Date()).getTimezoneOffset(), // timezone
    }
  }

  buildEcommEventParams(data) {
    var ecommEvents = Constants.ecommerceEvents;

    if (ecommEvents.indexOf(this.event) > -1) {
      this.setParam('ec', 'ecommerce');

      var items = data.items;

      if (this.event == 'view_product') {

        items = [{
          id: data.id,
          price: data.price,
          name: data.name,
          brand: data.brand,
          category: data.category,
          variant: data.variant
        }];

      }

      if (items) {

        var productIndex = 1;

        var productAttrs = {
          id: (data) => data.id,
          pr: (data) => data.price,
          qt: (data) => data.quantity,
          nm: (data) => data.name,
          brd: (data) => data.brand,
          ctg: (data) => data.category,
          vrn: (data) => data.variant,
        }

        for(var index in items) {
          var item = items[index];

          if (item.id && item.name) {
            for (var index in productAttrs) {
              if (productAttrs.hasOwnProperty(index)) {
                var param = productAttrs[index](item);
                if (helpers.isset(param)) {
                  this.setParam('pr' + productIndex + index , param);
                }
              }
            }

            productIndex++;
          } else {
            console.warn('[Omnichat Tracker] Missing item id / name.');
          }
        }

        /** mark as invalid data if none of the items valid **/
        if (productIndex === 1) {
          this.invalidData = true;
        }

      }

      if (this.event == 'purchase') {

        if (data.transaction_id) {
          var orderAttrs = {
            ti: (data) => data.transaction_id,
            crc: (data) => data.currency,
            tr: (data) => data.amount,
          }

          for (var index in orderAttrs) {
            if (orderAttrs.hasOwnProperty(index)) {
              var param = orderAttrs[index](data);
              if (helpers.isset(param)) {
                this.setParam(index , param);
              }
            }
          }
        } else {
          this.invalidData = true;
          console.warn('[Omnichat Tracker] Missing "transaction_id" for "purchase" action.');
        }
      }
    }
  }

  buildMemberMappingEventParams(data) {
    if (this.event == 'member_mapping') {

      if (data.memberId
        || data.memberEmail
        || data.memberPhone
        || data.memberName) {

        var memberAttrs = {
          mid: (data) => data.memberId,
          me: (data) => data.memberEmail,
          mp: (data) => data.memberPhone,
          mn: (data) => data.memberName,
          mpp: (data) => data.memberProfilePic,
        }

        for (var index in memberAttrs) {
          if (memberAttrs.hasOwnProperty(index)) {
            var param = memberAttrs[index](data);
            if (helpers.isset(param)) {
              this.setParam(index , param);
            }
          }
        }
      } else {
        this.invalidData = true;
      }
    }
  }

  setParam(key, val) {
    if (helpers.isset(val)) {
      this.params.push(`${key}=${encodeURIComponent(val)}`);
    } else {
      // this.params.push(`${key}=`);
    }
  }

  send() {
    // window.navigator.sendBeacon ? this.sendBeacon() : this.sendImage();
    if (!this.invalidData) {
      this.sendImage();
    }
  }

  sendBeacon() {
    window.navigator.sendBeacon(this.getSourceUrl());
  }

  sendImage() {
    this.img = document.createElement('img');
    this.img.src = this.getSourceUrl();
    this.img.style.display = 'none';
    this.img.width = '1';
    this.img.height = '1';
    document.getElementsByTagName('body')[0].appendChild(this.img);
  }

  getSourceUrl() {
    return `${EASYCHAT_CONFIG.TRACKING_PIXEL_ENDPOINT}?${this.params.join('&')}`;
  }
}

export default Pixel;