import './polyfill';
import './css/style.css';
import linkifyHtml from 'linkifyjs/html';
import EASYCHAT_CONFIG from './config/config';
import { UTM_CONSTANTS } from "./constants/utmConstants";
import * as utils from './utils';
import setupPixel from './pixel/setup';
import SocialSubscriber from "./SocialSubscriber";
import OptinModal from "./components/OptinModal";

var appKey = null;
var appColor = null;
var isLeft = false;
var ecPartner = null;
var enableParentScroll = true;
var badge = 0;
var autoMessageDict = null;
var appPluginType = 1;
var isMobileHashPushState = false;
var hidePlugin = false;
var hideChatPluginFor2021NewPlan = false;
var disableAutoAddWebRemarketingUTM = false;

var WIDGET_TYPE = {
  GENERAL_SUBSCRIBER: 1,
  ORDER_LINK: 2
};

var MESSAGE_TYPE = {
  WELCOME_MESSAGE: 'W',
  AUTO_MESSAGE: 'A',
  BOT_MESSAGE: 'B'
};

var CHATBOT_MESSAGE_TYPE = {
  MESSAGE_TYPE_CHATBOT_TEXT: 301,
  MESSAGE_TYPE_CHATBOT_CAROUSEL_TEMPLATE: 202,
  MESSAGE_TYPE_CHATBOT_YOUTUBE: 304
}

var MOBILE_MAX_WIDGET_HASH = '#max-widget';

var easychat_container_div = null;
var easychat_chat_div = null;

var currAutoMessageId = null;
var currAutoGeneratedCaseId = null;
var currPushMessageCaseId = null;
var websiteTitle = document.title;
var socialSubscriberIsShowed = false;
var welcomeMessageOrAutoMessageShowed = {};

var LinkifyOptions = {/* … */ };

var ecOTT = null;
var ecMemberId = null;
var ecToken = null;
var ecCurrentURL = null;
var ecClientId = null;

var bodyDisplayStyle = null;

// new omnichannel design
var iframeHeight = {
  index: 0,
  messageView: 0,
  chat: 0,
  qrcode: 0
};
var IFRAME_VARIATION = {
  MESSAGE_VIEW: 'message-view',
  CHAT: 'chat',
  QRCODE: 'qrcode'
};
var currentIframeView = IFRAME_VARIATION.MESSAGE_VIEW;

let EC_PIXEL_CONFIG_COOKIE = 'ec-pixel-config';

function init() {
  console.log('chat > init');
  let easychatScript = document.getElementById('easychatScript');

  if (document.getElementById('easychat-floating-button')) {
    // Normal situation init
    initNewOmnichatIcon();
    return;
  } else if (easychatScript) {
    // For backward compatibility
    initForBackwardCompatibility()
  } else {
    // For user who places omnichat code in head tag
    window.addEventListener('load', function () {
      setTimeout(function () {
        initNewOmnichatIcon()
      }, 100)
    });
  }
}

function initForBackwardCompatibility() {
  let appKey = easychatScript.dataset.appKey;
  let lang = easychatScript.dataset.lang;

  let cacheTime = 24 * 60 * 60 * 1000;
  let cacheKey = (Math.ceil(new Date() / cacheTime) * cacheTime);
  let chatCsWebUrl = `${EASYCHAT_CONFIG.IFRAME_HTML_SOURCE}?appkey=${appKey}&lang=${lang}&t=${cacheKey}`;

  let a = document.createElement('a');
  a.setAttribute('href', 'javascript:;');
  a.setAttribute('id', 'easychat-floating-button');

  let span = document.createElement('span');
  span.setAttribute('id', 'easychat-unread-badge');
  span.setAttribute('style', 'display: none');

  let d1 = document.createElement('div');
  d1.setAttribute('id', 'easychat-close-btn');
  d1.setAttribute('class', 'easychat-close-btn-close');

  let d2 = document.createElement('div'); d2.setAttribute('id', 'easychat-chat-dialog');
  d2.setAttribute('class', 'easychat-chat-dialog-close');

  let ifrm = document.createElement('iframe');
  ifrm.setAttribute('id', 'easychat-chat-dialog-iframe');
  ifrm.setAttribute('src', chatCsWebUrl);
  ifrm.style.width = '100%';
  ifrm.style.height = '100%';
  ifrm.style.frameborder = '0';
  ifrm.style.scrolling = 'on';
  d2.appendChild(ifrm);
  document.body.appendChild(a);
  document.body.appendChild(span);
  document.body.appendChild(d1);
  document.body.appendChild(d2);

  if (document.getElementById('easychat-floating-button')) {
    initNewOmnichatIcon();
  }
}

function initNewOmnichatIcon() {
  let floatIcon = document.getElementById('easychat-floating-button');
  let oldIcon = document.querySelector('#easychat-floating-button img');

  if (floatIcon) {
    if (oldIcon) {
      floatIcon.removeChild(oldIcon);
    }

    floatIcon.classList.add('omnichat-icon-wrapper');

    let leftEye = document.createElement('div');
    leftEye.setAttribute('class', 'omnichat-icon-eyes omnichat-icon-eyes--left');
    let rightEye = document.createElement('div');
    rightEye.setAttribute('class', 'omnichat-icon-eyes omnichat-icon-eyes--right');
    let mouth = document.createElement('div');
    mouth.setAttribute('class', 'omnichat-icon-mouth');
    floatIcon.appendChild(leftEye);
    floatIcon.appendChild(rightEye);
    floatIcon.appendChild(mouth);

    floatIcon.addEventListener('click', function () {
      toggleNewOmnichatIcon();
    }, false)
  }
}

function toggleNewOmnichatIcon() {
  let floatIcon = document.getElementById('easychat-floating-button') || document.getElementById('easychat-floating-button-left');
  floatIcon.classList.toggle('active');
}

function setChatPluginType(pluginType) {
  let floatIcon = document.getElementById('easychat-floating-button') || document.getElementById('easychat-floating-button-left');

  // 因為手機上的 dialog 是全螢幕，所以不需要 icon 的 animation，因為也看不到
  if (utils.isMobileDevice()) {
    floatIcon.classList.remove('plugin-animation');
    return;
  }

  switch (pluginType) {
    case 1:
      if (floatIcon.classList.contains('plugin-animation')) {
        floatIcon.classList.remove('plugin-animation');
      }
      break;
    case 2:
      if (!floatIcon.classList.contains('plugin-animation')) {
        floatIcon.classList.add('plugin-animation');
      }
      break;
    default:
      break;
  }
}

var ecFloatBtn = document.getElementById("easychat-floating-button");
if (ecFloatBtn == null) {
  ecFloatBtn = document.getElementById("easychat-floating-button-left");
}
// Hidden the FloatBtn first
if (ecFloatBtn != null) {
  ecFloatBtn.classList.add("easychat-float-botton-hide");
  var ecFloatBtnImg = ecFloatBtn.getElementsByTagName('img')[0];
  if (ecFloatBtnImg != null) {
    ecFloatBtnImg.classList.add("easychat-float-botton-hide");
  }
}

function createElementFromHTMLString(htmlString) {
  var div = document.createElement('div');
  div.innerHTML = htmlString.trim();

  // Change this to div.childNodes to support multiple top-level nodes
  return div.firstChild;
}

// include welcomeMessage / CampaginMessage / BotMessage
function getWelcomeOrAutoMessageHtml(autoMessageDict) {
  if (autoMessageDict.messageType === MESSAGE_TYPE.WELCOME_MESSAGE || autoMessageDict.messageType === MESSAGE_TYPE.BOT_MESSAGE) {
    return utils.linkifyAndFormatWelcomeMessage(autoMessageDict.message, disableAutoAddWebRemarketingUTM);
  }

  let autoMessageObj = autoMessageDict.message;
  let campaignName = encodeURI(autoMessageDict.campaignName);
  const isAddUTM = !disableAutoAddWebRemarketingUTM
  function parseLinkImage(url, imageUrl) {
    return `<a href="${isAddUTM ? utils.addEasychatUTM(url, UTM_CONSTANTS.SOURCE.OMNICHAT, UTM_CONSTANTS.MEDIUM.REMARKETING_WEBCHAT, `${UTM_CONSTANTS.CAMPAIGN.REMARKETING_WEBCHAT}_${campaignName}`) : url}" target="_blank">
        <img src="${imageUrl}">
    </a>` 
  }
  switch (autoMessageObj.type) {
    case CHATBOT_MESSAGE_TYPE.MESSAGE_TYPE_CHATBOT_TEXT:
      return utils.linkifyAndFormatRemarketingMessage(autoMessageObj.message, campaignName, isAddUTM);
    case CHATBOT_MESSAGE_TYPE.MESSAGE_TYPE_CHATBOT_CAROUSEL_TEMPLATE:
      let extension = autoMessageObj.extension[0];
      let linkImage = extension.defaultAction.url ?
      parseLinkImage(extension.defaultAction.url, extension.imageUrl) : `<img src="${extension.imageUrl}">`;
      return (
        `<div class="auto-message-carousel-image-wrapper">
            ${linkImage}
          </div>
          <div class="auto-message-carousel-description">${utils.linkifyAndFormatRemarketingMessage(extension.title, campaignName, isAddUTM)}</div>
          `
      );
    case CHATBOT_MESSAGE_TYPE.MESSAGE_TYPE_CHATBOT_YOUTUBE:
      let youtubeVideoId = utils.getYoutubeVideoId(autoMessageObj.extension.youtubeUrl);
      return (
        `<div class="youtube-embed-wrapper">
            <iframe width="210" height="140" src="//www.youtube.com/embed/${youtubeVideoId}" frameborder="0" allowfullscreen></iframe>
          </div>
          <div class="auto-message-youtube-description">${utils.linkifyAndFormatRemarketingMessage(autoMessageObj.message, campaignName, isAddUTM)}</div>`
      );
  }
}

function getEasychatWelcomeOrAutoMessageBlockHTML(autoMessageDict) {
  var autoMessageId = autoMessageDict.autoMessageId;
  var teamName = autoMessageDict.teamname || '';
  var teamDisplayName = autoMessageDict.teamDisplayName || '';
  var profileUrl = autoMessageDict.agents ? autoMessageDict.agents.profileUrl : '';
  var replyMessage = autoMessageDict.replymessage || '';
  return (
    '<div id="welcome-auto-message-block-' + autoMessageId + '" class="easychat-block-show welcome-auto-message-block">' +
    '<div class="easychat-chat-card easychat-chat-card-with-avatar">' +
    '<div class="easychat-chat-card-avatar easychat-chat-card-avatar-team">' +
    '<div class="easychat-team-avatar-container">' +
    '<img class="easychat-team-avatar easychat-team-avatar-small easychat-first-of-one-admins" id="easychat-team-avatar-id" src="' + profileUrl + '">' +
    '</div>' +
    '</div>' +
    '<div>' +
    '<div class="easychat-chat-card-author">' +
    '<div class="easychat-author-summary-name-from">' +
    '<span class="easychat-author-summary-name">' + teamDisplayName + '</span>' +
    '</div>' +
    '</div>' +
    '<div class="easychat-chat-card-body">' +
    '<div class="easychat-scrollable">' +
    '<div class="easychat-blocks">' +
    '<div class="easychat-block easychat-block-paragraph" id="easychat-block-message-id">' +
    getWelcomeOrAutoMessageHtml(autoMessageDict) +
    '</div>' +
    '</div>' +
    '</div>' +
    '</div>' +
    '<div class="easychat-chat-dismiss-button-mobile">&nbsp;</div>' +
    '</div>' +
    '</div>' +
    '<div class="easychat-chat-composer">' +
    '<span class="easychat-composer-placeholder">' + replyMessage + '</span>' +
    '</div>' +
    '</div>'
  );
}

function getEasychatPushMessageBlockHTML(pushMessageDict) {
  // currAutoMessageId = pushMessageDict.messageId;
  currPushMessageCaseId = pushMessageDict.caseId;
  var teamName = pushMessageDict.teamname || '';
  var message = '';

  if (pushMessageDict.message) {
    pushMessageDict.message.replace(new RegExp('\r?\n', 'g'), '<br />')
    message = linkifyHtml(pushMessageDict.message, LinkifyOptions);
  }

  var profileUrl = pushMessageDict.agents ? pushMessageDict.agents.profileUrl : '';
  var replyMessage = pushMessageDict.replymessage || '';

  return (
    '<div id="push-message-block-' + currPushMessageCaseId + '" class="easychat-block-show">' +
    '<div class="easychat-chat-card easychat-chat-card-with-avatar">' +
    '<div class="easychat-chat-card-avatar easychat-chat-card-avatar-team">' +
    '<div class="easychat-team-avatar-container">' +
    '<img class="easychat-team-avatar easychat-team-avatar-small easychat-first-of-one-admins" id="easychat-team-avatar-id" src="' + profileUrl + '">' +
    '</div>' +
    '</div>' +
    '<div>' +
    '<div class="easychat-chat-card-author">' +
    '<div class="easychat-author-summary-name-from">' +
    '<span class="easychat-author-summary-name">' + teamName + '</span>' +
    '</div>' +
    '</div>' +
    '<div class="easychat-chat-card-body">' +
    '<div class="easychat-scrollable">' +
    '<div class="easychat-blocks">' +
    '<div class="easychat-block easychat-block-paragraph" id="easychat-block-message-id">' +
    message +
    '</div>' +
    '</div>' +
    '</div>' +
    '</div>' +
    '<div class="easychat-chat-dismiss-button-mobile"></div>' +
    '</div>' +
    '</div>' +
    '<div class="easychat-chat-composer">' +
    '<span class="easychat-composer-placeholder">' + replyMessage + '</span>' +
    '</div>' +
    '</div>'
  );
}

function createEasychatContainer() {
  // easychat-container
  easychat_container_div = document.createElement('div');
  easychat_container_div.setAttribute('style', 'position: fixed; width: 0px; height: 0px; bottom: 0px; right: 0px; z-index: 9999999;');
  // easychat_container_div.setAttribute('class', 'easychat-container-hidden');
  easychat_container_div.setAttribute('id', 'easychat-container');

  // easychat-chat
  easychat_chat_div = document.createElement('div');
  easychat_chat_div.setAttribute('class', 'easychat-chat');

  easychat_container_div.appendChild(easychat_chat_div);
  document.body.appendChild(easychat_container_div);

  var ecFloatBtn = document.getElementById("easychat-floating-button");
  if (ecFloatBtn == null) {
    ecFloatBtn = document.getElementById("easychat-floating-button-left");
  }

  if (ecFloatBtn) {
    ecFloatBtn.addEventListener("click", function () {
      var iframe = document.getElementById('easychat-chat-dialog-iframe');
      iframe.contentWindow.postMessage(JSON.stringify({ 'close_auto_message': currAutoMessageId }), EASYCHAT_CONFIG.IFRAME_HTML_SOURCE);
      iframe.contentWindow.postMessage(JSON.stringify({ 'open_dialog': true }), EASYCHAT_CONFIG.IFRAME_HTML_SOURCE);
      utils.setCookie(currAutoMessageId, currAutoMessageId, 1);
      // utils.setCookie('is_general_subscriber_closed', true, 1);

      easychat_container_div.classList.remove('easychat-container-show');
      easychat_container_div.classList.add('easychat-container-hidden');
      easychat_chat_div.innerHTML = '';

      if (utils.isMobileDevice() || window.innerWidth < 415) {
        iframe.contentWindow.postMessage(JSON.stringify({ 'iframe-make-full-screen': true }), EASYCHAT_CONFIG.IFRAME_HTML_SOURCE);
      }
    });
  }
}

/******************************************************************************************
 * Modify to listen Push State Event Start
 *****************************************************************************************/
var _wr = function (type) {
  var orig = history[type];
  return function () {
    var rv = orig.apply(this, arguments);
    var e = new CustomEvent(type);
    e.arguments = arguments;
    window.dispatchEvent(e);
    return rv;
  };
};

history.pushState = _wr('pushState');
history.replaceState = _wr('replaceState');

var passTokenToIframeForAuth = function () {

  /************************************************************************************************
   * Authenticate
   *************************************************************************************************/

  ecToken = utils.getCookie("ec-token");
  ecMemberId = utils.getCookie("ec-mid");
  ecClientId = utils.getCookie("gaClientId");

  var ecAuthObj = {};
  ecAuthObj.easychatAuth = {};
  ecAuthObj.easychatAuth.ecToken = ecToken;
  ecAuthObj.easychatAuth.ecOTT = ecOTT;
  ecAuthObj.easychatAuth.ecMemberId = ecMemberId;
  ecAuthObj.easychatAuth.clientId = ecClientId;
  ecAuthObj.easychatAuth.customerDomain = location.host;

  var iframe = document.getElementById('easychat-chat-dialog-iframe');
  if (iframe != null) {
    // console.log('chat > pass token')
    iframe.contentWindow.postMessage(JSON.stringify(ecAuthObj), EASYCHAT_CONFIG.IFRAME_HTML_SOURCE);
  } else {
    // console.log('chat > dialog iframe does not exist');
  }

  ecCurrentURL = window.location.href;
  if (iframe != null) {
    iframe.contentWindow.postMessage(JSON.stringify({ 'current-url': ecCurrentURL }), EASYCHAT_CONFIG.IFRAME_HTML_SOURCE);
  }
}

function easychatPluginEventInit() {
  // console.log('chat > init plugin');
  // your page initialization code here
  // the DOM will be available here
  if (typeof CHAT_PLUGIN_DEBUG_ON !== 'undefined' && CHAT_PLUGIN_DEBUG_ON) {
    console.log('Chat plugin init function execute');
  }

  if (location.href.includes(MOBILE_MAX_WIDGET_HASH)) {
    history.pushState('', document.title, location.href.replace(MOBILE_MAX_WIDGET_HASH, ''));
  }

  var ecFloatBtn = document.getElementById("easychat-floating-button");
  if (ecFloatBtn == null) {
    ecFloatBtn = document.getElementById("easychat-floating-button-left");
  }

  // prevent user load easychat.js too early
  if (!ecFloatBtn) return;

  // create easychat-chat outer wrapper
  createEasychatContainer();

  var ecCloseBtn = document.getElementById("easychat-close-btn");
  var ecChatDialog = document.getElementById("easychat-chat-dialog");

  ecFloatBtn.onclick = function (e) {
    e.preventDefault();

    var unReadSpan = document.getElementById("easychat-unread-badge");
    if (unReadSpan == null) {
      unReadSpan = document.getElementById("easychat-unread-badge-left");
    }

    if (utils.hasClass(ecChatDialog, "easychat-chat-dialog-open")) {
      ecChatDialog.classList.remove("easychat-chat-dialog-open");

      // fix for cyberbiz iframe height
      if (bodyDisplayStyle) {
        document.body.style.display = bodyDisplayStyle; // resume body display style
      }

      if (utils.isMobileDevice()) {
        if (badge > 0) {
          unReadSpan.style.display = 'block';
        } else {
          unReadSpan.style.display = 'none';
        }
        utils.enableScroll();
        utils.removeClass(document.body, "prevent-scroll");
        document.getElementById('easychat-chat-dialog-iframe').scrolling = 'no';
        // document.body.style.overflow = 'scroll';
        enableParentScroll = true;

        var iframe = document.getElementById('easychat-chat-dialog-iframe');
        iframe.contentWindow.postMessage(JSON.stringify({ 'easychat-chat-dialog-status': "closed" }), EASYCHAT_CONFIG.IFRAME_HTML_SOURCE);
        // ecChatDialog.style.display = 'none';
        // ecChatDialog.style.cssText = 'height: 0px; width: 0px';
      }
    } else {
      ecChatDialog.classList.add("easychat-chat-dialog-open");

      // fix for cyberbiz iframe height
      if (document.body.style.display === 'flex') {
        bodyDisplayStyle = document.body.style.display; // store old body display style
        document.body.style.display = 'block';
      }

      if (isLeft == true) {
        ecCloseBtn.style.right = 0;
        if (document.body.clientWidth > 415) {
          ecCloseBtn.style.left = 380 - 30 + "px";
        }
      }
      if (utils.isMobileDevice()) {
        utils.disableScroll();
        //  document.body.style.overflow = 'hidden';
        document.getElementById('easychat-chat-dialog-iframe').scrolling = 'on';
        document.body.className += " " + "prevent-scroll";

        enableParentScroll = false;

        unReadSpan.style.display = 'none';

        /*
         * deal with max-widget hash
         * 用來避免手機在按上一頁之後，直接跳出網站的狀況，所以加了一個 hash
         */
        isMobileHashPushState = true;
        history.pushState(null, document.title, location.href + MOBILE_MAX_WIDGET_HASH);
      }

      var iframe = document.getElementById('easychat-chat-dialog-iframe');
      iframe.contentWindow.postMessage(JSON.stringify({ 'easychat-chat-dialog-status': "open" }), EASYCHAT_CONFIG.IFRAME_HTML_SOURCE);

    }

    if (utils.hasClass(ecCloseBtn, "easychat-close-btn-show")) {
      ecCloseBtn.classList.remove("easychat-close-btn-show");
    } else {
      ecCloseBtn.classList.add("easychat-close-btn-show");
    }
  };

  document.getElementById("easychat-close-btn").onclick = function () {
    if (window.innerWidth > 415) {
      return;
    }

    ecChatDialog.classList.remove("easychat-chat-dialog-open");
    ecCloseBtn.classList.remove("easychat-close-btn-show");

    var unReadSpan = document.getElementById("easychat-unread-badge");
    if (unReadSpan == null) {
      unReadSpan = document.getElementById("easychat-unread-badge-left");
    }

    // remove new icon active class
    let chatPluginIcon = document.getElementById("easychat-floating-button") || document.getElementById("easychat-floating-button-left");
    if (chatPluginIcon.classList.contains('active')) {
      chatPluginIcon.classList.remove('active');
    } else {
      chatPluginIcon.classList.add('active');
    }

    // fix for cyberbiz iframe height
    if (bodyDisplayStyle) {
      document.body.style.display = bodyDisplayStyle; // resume body display style
    }

    if (utils.isMobileDevice()) {
      utils.enableScroll();
      utils.removeClass(document.body, "prevent-scroll");
      document.getElementById('easychat-chat-dialog-iframe').scrolling = 'no';
      //  document.body.style.overflow = 'scroll';
      enableParentScroll = true;

      if (badge > 0) {
        unReadSpan.style.display = 'block';
      } else {
        unReadSpan.style.display = 'none';
      }

      var iframe = document.getElementById('easychat-chat-dialog-iframe');
      iframe.contentWindow.postMessage(JSON.stringify({ 'easychat-chat-dialog-status': "closed" }), EASYCHAT_CONFIG.IFRAME_HTML_SOURCE);

      /*
       * 若按了關閉 dialog 按鈕之後，要拿掉 #max-widget
       */
      if (location.href.includes(MOBILE_MAX_WIDGET_HASH)) {
        isMobileHashPushState = true;
        history.replaceState(null, document.title, location.href.replace(MOBILE_MAX_WIDGET_HASH, ''));
        history.back();
      }
    }
  };

  var url = document.getElementById('easychat-chat-dialog-iframe').src;
  if (url.split('?').length > 1) {
    var params = url.split('?')[1].split('&');
    if (params.length > 1) {
      var appKeyObject = params[0].split('=');
      // var key   = appKeyObject[0];
      var value = appKeyObject[1];
      appKey = value;
    }
  }

  var queryParaJson = utils.getJsonFromUrl();
  if (queryParaJson != null) {
    if (queryParaJson.utm_source != null && queryParaJson.utm_medium != null && queryParaJson.utm_campaign != null && queryParaJson.ott != null) {
      if (queryParaJson.utm_source === "easychat" && queryParaJson.utm_medium === "email" && queryParaJson.utm_campaign === "notification") {
        ecOTT = queryParaJson.ott;
      }
    }
  }
  // console.log('chat > auth on init')
  passTokenToIframeForAuth();
  // console.log("this url - " + ecCurrentURL);

  /************************************************************************************************
   * PushState Event
   *************************************************************************************************/
  window.addEventListener("pushState", function (event) {
    /*
     * 如果是為了控制 mobile dialog，則不要再發 new-url event
     * 不然會再次 postUpdateNewActivity / getAutoAction
     */
    if (isMobileHashPushState) {
      isMobileHashPushState = false;
      return;
    }

    let queryParaJson = utils.getJsonFromUrl();
    if (ecCurrentURL === location.href) return;
    if (queryParaJson['ec-mapping-processed']) return;

    ecCurrentURL = location.href;

    // Remove old autoMessage when SPA change page
    let welcomeAutoMessageBlock = document.querySelector('.welcome-auto-message-block');
    if (welcomeAutoMessageBlock && welcomeAutoMessageBlock.classList.contains('easychat-block-show')) {
      welcomeMessageOrAutoMessageShowed = {};
      welcomeAutoMessageBlock.remove();
    }

    var iframe = document.getElementById('easychat-chat-dialog-iframe');
    if (iframe != null) {
      iframe.contentWindow.postMessage(JSON.stringify({ 'new-url': ecCurrentURL }), EASYCHAT_CONFIG.IFRAME_HTML_SOURCE);
    }
  });

  /************************************************************************************************
   * PopState Event
   *************************************************************************************************/
  window.addEventListener('popstate', function (event) {
    
    if (utils.isMobileDevice()) {
      handleCloseBtnPressed();
    }

    if (ecCurrentURL === location.href) return;

    ecCurrentURL = location.href;
    // console.log("popstate url : " + ecCurrentURL);
    var iframe = document.getElementById('easychat-chat-dialog-iframe');
    if (iframe != null) {
      iframe.contentWindow.postMessage(JSON.stringify({ 'new-url': ecCurrentURL }), EASYCHAT_CONFIG.IFRAME_HTML_SOURCE);
    }
  }, false);
}

function handleChildMessage(e) {
  // validate message origin
  if (e.origin !== EASYCHAT_CONFIG.IFRAME_HTML_SOURCE) {
    return;
  }

  var data = null;
  var easychatChatDialog = document.querySelector('#easychat-chat-dialog');
  // var easychatChatDialog = document.querySelector('.easychat-chat-dialog-open');
  var floatIcon = document.getElementById('easychat-floating-button') || document.getElementById('easychat-floating-button-left');

  try {
    data = JSON.parse(e.data);

    if (typeof CHAT_PLUGIN_DEBUG_ON !== 'undefined' && CHAT_PLUGIN_DEBUG_ON) {
      // console.log('======= Parent receive event from iframe');
      // console.log(data);
      // console.log('========================================');
    }
  } catch (e) {
    return false;
  }

  if (e.data == null || e.data == "") {
    return;
  }

  if (data['color_position'] != null) {
    let colorPositionArray = data['color_position'];

    appColor = colorPositionArray[0];
    isLeft = colorPositionArray[1];
    appPluginType = colorPositionArray[2];

    var ecFloatBtn = document.getElementById("easychat-floating-button");
    if (ecFloatBtn == null) {
      ecFloatBtn = document.getElementById("easychat-floating-button-left");
    }

    var unReadSpan = document.getElementById("easychat-unread-badge");
    if (unReadSpan == null) {
      unReadSpan = document.getElementById("easychat-unread-badge-left");
    }

    var divElement = document.getElementById("easychat-chat-dialog");

    if (isLeft == 0) {
      if (ecFloatBtn != null) {
        ecFloatBtn.id = "easychat-floating-button";
      }

      if (unReadSpan != null) {
        unReadSpan.id = "easychat-unread-badge";
      }

      if (divElement != null) {
        divElement.classList.remove("easychat-chat-dialog-close-left")
        divElement.classList.add("easychat-chat-dialog-close")
      }
    } else {
      if (ecFloatBtn != null) {
        ecFloatBtn.id = "easychat-floating-button-left";
      }

      if (unReadSpan != null) {
        unReadSpan.id = "easychat-unread-badge-left";
      }

      if (divElement != null) {
        divElement.classList.remove("easychat-chat-dialog-close")
        divElement.classList.add("easychat-chat-dialog-close-left")
      }
    }

    setChatPluginType(appPluginType);
    ecFloatBtn.style.backgroundColor = appColor;
    ecFloatBtn.classList.remove("easychat-float-botton-hide");
    ecFloatBtn.classList.add("easychat-float-botton-show");
    var ecFloatBtnImg = ecFloatBtn.getElementsByTagName('img')[0];
    if (ecFloatBtnImg != null) {
      ecFloatBtnImg.classList.remove("easychat-float-botton-hide");
      ecFloatBtnImg.classList.add("easychat-float-botton-show");
    }

  } else if (data['badge'] != null) {
    badge = data['badge'];
    var spanElement = document.getElementById("easychat-unread-badge");
    if (spanElement == null) {
      spanElement = document.getElementById("easychat-unread-badge-left");
    }
    spanElement.innerHTML = badge.toString();
    if (badge > 0) {
      websiteTitle = document.title;
      var appLang = data['appLang'];

      if (appLang == 'en') document.title = "(" + badge + ")" + " unread | " + websiteTitle;
      else document.title = "(" + badge + ")" + " 封未讀訊息 | " + websiteTitle;
      spanElement.style.display = 'block';
    } else {
      // update document title only if the title changed by the chat plugin

      if (document.title.indexOf("unread | ") > 0
        || document.title.indexOf("封未讀訊息 | ") > 0) {
        document.title = websiteTitle;
      }
      spanElement.style.display = 'none';
    }
  } else if (data['auto_message'] != null) {
    autoMessageDict = data['auto_message'];

    // if plugin is hide, should not show
    if (hidePlugin) {
      return;
    }

    // if cookie has the same messageId then not show
    if (utils.getCookie(autoMessageDict.autoMessageId)) return;

    if (document.getElementById("easychat-chat-dialog").classList.contains('easychat-chat-dialog-open')) {
      return;
    }

    // handle welcome popup block display
    if (
      (autoMessageDict.messageType === MESSAGE_TYPE.WELCOME_MESSAGE ||
        autoMessageDict.messageType === MESSAGE_TYPE.BOT_MESSAGE) &&
      !autoMessageDict.welcomeMessagePopupEnabled
    )
      return;

    // if one welcome or auto message has been showed, then don't show another
    var welcomeAutoMessageBlock = document.querySelector('.welcome-auto-message-block');
    if (welcomeAutoMessageBlock && welcomeAutoMessageBlock.classList.contains('easychat-block-show')) return;

    var ecFloatBtn = document.getElementById("easychat-floating-button");
    // var isLeft = false;
    if (ecFloatBtn == null) {
      ecFloatBtn = document.getElementById("easychat-floating-button-left");
      // isLeft = true;
    }

    if (autoMessageDict != null) {
      if (welcomeMessageOrAutoMessageShowed[autoMessageDict.autoMessageId]) return;

      /*********************************************************************************
       * Auto Message Element Start
       **********************************************************************************/
      var handleAutoMessage = function () {
        var easychat_container_div = document.getElementById("easychat-container");
        var easychat_chat_div = document.querySelector('.easychat-chat');
        // check easychatPluginEventInit is excuted
        if (easychat_chat_div) {
          if (isLeft == true) {
            easychat_chat_div.style.right = 0;
            easychat_chat_div.style.left = "50px";
          }

          // getEasychatWelcomeOrAutoMessageHTML
          easychat_chat_div.prepend(createElementFromHTMLString(getEasychatWelcomeOrAutoMessageBlockHTML(autoMessageDict)))

          // record that welcomeMessage or reMarketingMessage is showed, when chat server reconnect, we won't show duplicate message.
          welcomeMessageOrAutoMessageShowed[autoMessageDict.autoMessageId] = true;

          // trigger campaignMessage has showed
          document.getElementById('easychat-chat-dialog-iframe').contentWindow.postMessage(JSON.stringify({ 'has_triggered_campaign_message': autoMessageDict }), EASYCHAT_CONFIG.IFRAME_HTML_SOURCE);

          // get Elements
          var rootId = '#welcome-auto-message-block-' + autoMessageDict.autoMessageId;
          var message_block_div = document.querySelector(rootId);
          // easychat_chat_dismiss_button_mobile_div = document.querySelector(rootId + ' .easychat-chat-dismiss-button-mobile');
          // easychat_chat_composer_div = document.querySelector(rootId + ' .easychat-chat-composer');

          document.querySelector(rootId + ' .easychat-chat-dismiss-button-mobile').addEventListener("click", function () {
            if (currAutoMessageId != null) {
              var iframe = document.getElementById('easychat-chat-dialog-iframe');
              iframe.contentWindow.postMessage(JSON.stringify({ 'close_auto_message': currAutoMessageId }), EASYCHAT_CONFIG.IFRAME_HTML_SOURCE);
              utils.setCookie(currAutoMessageId, currAutoMessageId, 1); // record the message showed

              message_block_div.classList.remove('easychat-block-show');
              message_block_div.classList.add('easychat-block-hidden');
            }
          });

          document.querySelector(rootId + ' .easychat-chat-composer').addEventListener("click", function () {
            var iframe = document.getElementById('easychat-chat-dialog-iframe');
            // iframe.contentWindow.postMessage(JSON.stringify({'reply_to_auto_message': currAutoGeneratedCaseId}), '*');
            iframe.contentWindow.postMessage(JSON.stringify({ 'reply_to_auto_message': currAutoGeneratedCaseId }), EASYCHAT_CONFIG.IFRAME_HTML_SOURCE);
            iframe.contentWindow.postMessage(JSON.stringify({ 'close_auto_message': currAutoMessageId }), EASYCHAT_CONFIG.IFRAME_HTML_SOURCE);
            utils.setCookie(currAutoMessageId, currAutoMessageId, 1); // record the message showed
            easychat_container_div.classList.remove('easychat-container-show');
            easychat_container_div.classList.add('easychat-container-hidden');
            ecFloatBtn.click();
          });

          easychat_container_div = document.getElementById('easychat-container');
          if (autoMessageDict.autoMessageId != null && autoMessageDict.autoGeneratedCaseId != null) {
            if (utils.getEasychatCookie(autoMessageDict.autoMessageId) == null) {
              currAutoMessageId = autoMessageDict.autoMessageId;
              currAutoGeneratedCaseId = autoMessageDict.autoGeneratedCaseId;
              easychat_container_div.classList.remove('easychat-container-hidden');
              easychat_container_div.classList.add('easychat-container-show');
            }
          }
          /*********************************************************************************
           * Auto Message Element End
           **********************************************************************************/
        } else {
          // after 0.6s executed this function again
          setTimeout(handleAutoMessage, 600);
        }
      }
      handleAutoMessage();
    }

  } else if (data['abandoned-optin']) {
    // if partner is 91App and at login page then do not init socialSubscriber
    if(hasFacebookSDKConflict(ecPartner)) return;
    let optinModal = data['abandoned-optin'];
    optinModal.ecMemberId = ecMemberId;

    new OptinModal(optinModal, data['appLang']);
  } else if (data['abandoned-optin-reset-cookie']) {
    utils.deleteCookie('cart_optin_show_times')
    utils.deleteCookie('add_to_cart_times')
    utils.deleteCookie('is_clicked_cart_optin')
    utils.deleteCookie('has_phone_number')
  } else if (data['social_subscriber'] != null) {
    var socialSubscriber = data['social_subscriber'];
    var appLang = data.appLang;

    // if plugin is hide, should not show
    if (hidePlugin) {
      return;
    }

    // if partner is 91App and at login page then do not init socialSubscriber
    if(hasFacebookSDKConflict(ecPartner)) return;

    if (document.getElementById("easychat-chat-dialog").classList.contains('easychat-chat-dialog-open')) {
      return;
    }

    var ecFloatBtn = document.getElementById("easychat-floating-button");
    // var isLeft = false;
    if (ecFloatBtn == null) {
      ecFloatBtn = document.getElementById("easychat-floating-button-left");
      // isLeft = true;
    }

    if (socialSubscriber) {
      if ((socialSubscriber.widgetType === WIDGET_TYPE.GENERAL_SUBSCRIBER && utils.getCookie('is_general_subscriber_closed')) || socialSubscriberIsShowed) return;

      var handleSocialSubscriber = function () {
        var easychat_container_div = document.getElementById("easychat-container");
        var easychat_chat_div = document.querySelector('.easychat-chat');

        if (easychat_chat_div) {
          if (isLeft == true) {
            easychat_chat_div.style.right = 0;
            easychat_chat_div.style.left = "50px";
          }
          /*********************************************************************************
           * Social Subscriber Element Start
           **********************************************************************************/
          // new SocialSubscriber
          socialSubscriber.ecMemberId = ecMemberId;
          new SocialSubscriber(socialSubscriber, appLang);

          // record that social subscriber is showed, when chat server reconnect, we won't show duplicate subscriber
          socialSubscriberIsShowed = true;
        } else {
          // after 0.3s executed this function again
          setTimeout(handleSocialSubscriber, 300);
        }
      }
      handleSocialSubscriber();
    }
  } else if (data['get_line_link_number_request']) {
    var payload = data['get_line_link_number_request']
    var iframe = document.getElementById('easychat-chat-dialog-iframe');
    iframe.contentWindow.postMessage(JSON.stringify({ 'get_line_link_number_request': payload }), EASYCHAT_CONFIG.IFRAME_HTML_SOURCE);

  } else if (data['push_message'] != null) {
    var pushMessageDict = data['push_message'];
    
    // if plugin is hide, should not show
    if (hidePlugin) {
      return;
    }

    if (document.getElementById("easychat-chat-dialog").classList.contains('easychat-chat-dialog-open')) {
      return;
    }

    var easychat_container_div = document.getElementById("easychat-container");
    easychat_container_div.classList.remove("easychat-container-hidden");
    easychat_container_div.classList.add('easychat-container-show');

    var ecFloatBtn = document.getElementById("easychat-floating-button");
    // var isLeft = false;
    if (ecFloatBtn == null) {
      ecFloatBtn = document.getElementById("easychat-floatihas_triggered_campaign_messageng-button-left");
      // isLeft = true;
    }

    if (pushMessageDict != null) {
      var handlePushMessage = function () {
        var easychat_container_div = document.getElementById("easychat-container");
        var easychat_chat_div = document.querySelector('.easychat-chat');

        if (easychat_chat_div) {
          if (isLeft == true) {
            easychat_chat_div.style.right = 0;
            easychat_chat_div.style.left = "50px";
          }
          //easychat_container Append Element;
          // easychat_container_div.appendChild(easychat_chat_div);
          easychat_chat_div.prepend(createElementFromHTMLString(getEasychatPushMessageBlockHTML(pushMessageDict)));

          // get Elements
          var pushMessageBlockRootId = '#push-message-block-' + currPushMessageCaseId;
          var message_block_div = document.querySelector(pushMessageBlockRootId);
          // easychat_chat_dismiss_button_mobile_div = document.querySelector(pushMessageBlockRootId + ' .easychat-chat-dismiss-button-mobile');
          // easychat_chat_composer_div = document.querySelector(pushMessageBlockRootId + ' .easychat-chat-composer');

          document.querySelector(pushMessageBlockRootId + ' .easychat-chat-dismiss-button-mobile').addEventListener("click", function () {
            var iframe = document.getElementById('easychat-chat-dialog-iframe');
            // iframe.contentWindow.postMessage(JSON.stringify({'close_auto_message': currAutoMessageId}), '*');
            message_block_div.classList.remove('easychat-block-show');
            message_block_div.classList.add('easychat-block-hidden');
          });

          document.querySelector(pushMessageBlockRootId + ' .easychat-chat-composer').addEventListener("click", function () {
            var iframe = document.getElementById('easychat-chat-dialog-iframe');
            iframe.contentWindow.postMessage(JSON.stringify({ 'reply_to_auto_message': currPushMessageCaseId }), EASYCHAT_CONFIG.IFRAME_HTML_SOURCE);
            // iframe.contentWindow.postMessage(JSON.stringify({'close_auto_message': currAutoMessageId}), '*');
            easychat_container_div.classList.remove('easychat-container-show');
            easychat_container_div.classList.add('easychat-container-hidden');
            ecFloatBtn.click();
          });
        } else {
          setTimeout(handlePushMessage, 500);
        }
      }
      handlePushMessage();
    }
  } else if (data['livechat-enabled'] != null) {
    let replyBox = document.querySelector('.easychat-chat-composer');
    if (replyBox != null) {
      if (data['livechat-enabled'] === true) replyBox.style.display = 'block';
      else replyBox.style.display = 'none';
    }
  } else if (data['bot_link_clicked'] != null) {
    /*
     * Don't need to add utm at url in bot message content
     * Dont't need to add utm at 1 to 1 webchat message
     * Only add utm at bot button and Carousel url at backend
     */
    // var href = utils.addEasychatUTM(data['bot_link_clicked'], UTM_CONSTANTS.SOURCE.OMNICHAT, UTM_CONSTANTS.MEDIUM.WEBCHAT, UTM_CONSTANTS.CAMPAIGN.CHATBOT);
    let href = data['bot_link_clicked'];
    if(ecPartner === 'shopify') {
      let link = document.createElement('a');
      link.setAttribute('href', href);
      link.setAttribute('target', '_blank');
      link.click();
      return
    }
    window.open(href, '_blank');
  } else if (data['ig_widget_clicked'] != null) {
    var href = data['ig_widget_clicked'];
    window.open(href, '_blank');
  } else if (data['twitter_widget_clicked'] != null) {
    var href = data['twitter_widget_clicked'];
    window.open(href, '_blank');
  } else if (data['facebook_widget_clicked'] != null) {
    var href = data['facebook_widget_clicked'];
    window.open(href, '_blank');
  } else if (data['line_widget_clicked'] != null) {
    var href = data['line_widget_clicked'];
    if(ecPartner === 'shopify') {
      let link = document.createElement('a');
      link.setAttribute('href', href);
      link.setAttribute('target', '_blank');
      link.click();
      return
    }
    window.open(href, '_blank');
  } else if (data['whatsapp_widget_clicked'] != null) {
    var href = data['whatsapp_widget_clicked'];
    window.open(href, '_blank');
  } else if (data['fb_messenger_widget_clicked'] != null) {
    var href = data['fb_messenger_widget_clicked'];
    window.open(href, '_blank');
  } else if (data['phone_widget_clicked'] != null) {
    var PhoneNumber = data['phone_widget_clicked'];
    window.location.href = "tel://" + PhoneNumber;
  } else if (data['email_widget_clicked'] != null) {
    var emailAddress = data['email_widget_clicked'];
    window.location.href = "mailto:" + emailAddress;
  } else if (data['get_partner_memberId_key'] != null) {
    var key = data['get_partner_memberId_key'];
    var memberId = utils.getCookie(key);
    if (memberId != null) {
      utils.setCookie("ec-mid", memberId, 30);
      var iframe = document.getElementById('easychat-chat-dialog-iframe');
      if (iframe != null) {
        iframe.contentWindow.postMessage(JSON.stringify({ 'pass_partner_member_id': memberId }), EASYCHAT_CONFIG.IFRAME_HTML_SOURCE);
      }
    }
  } else if (data['supsend-update-color-position'] != null) {
    appColor = "#3b5998";
    isLeft = 0;

    var ecFloatBtn = document.getElementById("easychat-floating-button");
    if (ecFloatBtn == null) {
      ecFloatBtn = document.getElementById("easychat-floating-button-left");
    }

    var unReadSpan = document.getElementById("easychat-unread-badge");
    if (unReadSpan == null) {
      unReadSpan = document.getElementById("easychat-unread-badge-left");
    }

    var divElement = document.getElementById("easychat-chat-dialog");

    if (isLeft == 0) {
      if (ecFloatBtn != null) {
        ecFloatBtn.id = "easychat-floating-button";
      }

      if (unReadSpan != null) {
        unReadSpan.id = "easychat-unread-badge";
      }

      if (divElement != null) {
        divElement.classList.remove("easychat-chat-dialog-close-left")
        divElement.classList.add("easychat-chat-dialog-close")
      }
    } else {
      if (ecFloatBtn != null) {
        ecFloatBtn.id = "easychat-floating-button-left";
      }

      if (unReadSpan != null) {
        unReadSpan.id = "easychat-unread-badge-left";
      }

      if (divElement != null) {
        divElement.classList.remove("easychat-chat-dialog-close")
        divElement.classList.add("easychat-chat-dialog-close-left")
      }
    }

    ecFloatBtn.style.backgroundColor = appColor;
    ecFloatBtn.classList.remove("easychat-float-botton-hide");
    ecFloatBtn.classList.add("easychat-float-botton-show");
    var ecFloatBtnImg = ecFloatBtn.getElementsByTagName('img')[0];
    if (ecFloatBtnImg != null) {
      ecFloatBtnImg.classList.remove("easychat-float-botton-hide");
      ecFloatBtnImg.classList.add("easychat-float-botton-show");
    }
  } else if (data['store-ec-token'] != null) {

    let ecStoreToken = data['store-ec-token'];
    ecToken = ecStoreToken.easychatAccessToken;
    appColor = ecStoreToken.appColor;
    isLeft = ecStoreToken.appPosition;
    ecPartner = ecStoreToken.ecPartner;
    appPluginType = ecStoreToken.pluginType;
    hideChatPluginFor2021NewPlan = !ecStoreToken.showChatPlugin;
    disableAutoAddWebRemarketingUTM = ecStoreToken.disableAutoAddWebRemarketingUTM;


    /* For custom icon */
    let enableCustomizedIcon = ecStoreToken.enableCustomizedIcon;
    let customizedIconPath = ecStoreToken.customizedIconPath;

    /** for tracking pixel **/
    let partnerShopId = ecStoreToken.shopId;
    let ecUsername = ecStoreToken.username;
    let ecTeam = ecStoreToken.team;
    let enablePixel = ecStoreToken.enablePixel || false;
    let txLinkTrackingLifetime = ecStoreToken.txLinkTrackingLifetime;

    if (ecPartner !== '91App') {
      let pixelInitConfig = {
        shopId: partnerShopId,
        shopPlatform: ecPartner,
        team: ecTeam,
        ecid: ecUsername,
        enablePixel: enablePixel,
        txLinkTrackingLifetime: txLinkTrackingLifetime
      }
      utils.setCookie(EC_PIXEL_CONFIG_COOKIE, utils.utoa(JSON.stringify(pixelInitConfig)), 365 * 2);
    }

    if (ecToken != null) {
      utils.setCookie("ec-token", ecToken, 365 * 2);
    }

    var ecFloatBtn = document.getElementById("easychat-floating-button");
    if (ecFloatBtn == null) {
      ecFloatBtn = document.getElementById("easychat-floating-button-left");
    }

    var unReadSpan = document.getElementById("easychat-unread-badge");
    if (unReadSpan == null) {
      unReadSpan = document.getElementById("easychat-unread-badge-left");
    }

    var divElement = document.getElementById("easychat-chat-dialog");

    if (isLeft == 0) {
      if (ecFloatBtn != null) {
        ecFloatBtn.id = "easychat-floating-button";
      }

      if (unReadSpan != null) {
        unReadSpan.id = "easychat-unread-badge";
      }

      if (divElement != null) {
        divElement.classList.remove("easychat-chat-dialog-close-left")
        divElement.classList.add("easychat-chat-dialog-close")
      }
    } else {
      if (ecFloatBtn != null) {
        ecFloatBtn.id = "easychat-floating-button-left";
      }

      if (unReadSpan != null) {
        unReadSpan.id = "easychat-unread-badge-left";
      }

      if (divElement != null) {
        divElement.classList.remove("easychat-chat-dialog-close")
        divElement.classList.add("easychat-chat-dialog-close-left")
      }
    }

    setChatPluginType(appPluginType);

    /*
     * Deal with custom omnichat icon for enterprise plan
     */
    if (enableCustomizedIcon && customizedIconPath) {
      if (!document.getElementById('custom-icon-img')) {
        ecFloatBtn.classList.add('custom-icon');
        ecFloatBtn.classList.remove('plugin-animation');
        ecFloatBtn.prepend(createElementFromHTMLString(`<img id="custom-icon-img" src="${customizedIconPath}">`));
      }
    } else {
      // 如果不是客製化 icon 才幫 Omnichat 背景加上後台設定的顏色
      ecFloatBtn.style.background = appColor;
    }

    ecFloatBtn.classList.remove("easychat-float-botton-hide");
    ecFloatBtn.classList.add("easychat-float-botton-show");

    /*
     * Deal with small plugin cause by user change css
     * ex: Waca
     */
    setTimeout(function () {
      if (ecFloatBtn.clientWidth <= 50) {
        ecFloatBtn.classList.add('small-plugin');
      }
    }, 100);

    var ecFloatBtnImg = ecFloatBtn.getElementsByTagName('img')[0];
    if (ecFloatBtnImg != null) {
      ecFloatBtnImg.classList.remove("easychat-float-botton-hide");
      ecFloatBtnImg.classList.add("easychat-float-botton-show");
    }

    // do Easychat user mapping
    utils.doUserMappingFromMessagingChannel(utils.getJsonFromUrl(), ecMemberId, ecStoreToken.features);

    //get EC OrderId for social subscriber
    if (ecPartner) {
      utils.sendEcOrderIdToIframe(ecPartner);
    }

    initTrackingPixel({
      shopId: partnerShopId,
      shopPlatform: ecPartner,
      team: ecTeam,
      ecid: ecUsername,
      enablePixel: enablePixel,
      txLinkTrackingLifetime: txLinkTrackingLifetime
    });

    // hide chat plugin if inside 91App LINE binding webview
    if (ecPartner === '91App'
        && (window.location.href.indexOf('/LineAuth/Auth') > -1
          || window.location.href.indexOf('/LineAuth/LineOAuthCallback') > -1
          || window.location.href.indexOf('/Pay/Finish') > -1)
        ) {
      ecFloatBtn.classList.remove("easychat-float-botton-show");
      ecFloatBtn.classList.add("easychat-float-botton-hide");
      hidePlugin = true;
    }

    // 加入 91App icon style 調整的 class
    if (ecPartner === '91App') {
      ecFloatBtn.classList.add('nine-one-icon-style');

      // Fix float button right for Timberland
      if (partnerShopId === '1257') {
        let chatIcon = document.querySelector('#easychat-floating-button');
        if (utils.isMobileDevice() || window.innerWidth < 576 ) {
          chatIcon.style.setProperty('right', '10px', 'important');
        } else {
          chatIcon.style.setProperty('right', '40px', 'important');
        }
      }


      // 手機版結帳流程調整 z-index
      if (window.location.href.indexOf('/ShoppingCart/Index') > -1 && (utils.isMobileDevice() || window.innerWidth < 576)) {
        ecFloatBtn.classList.add('nine-one-checkout-flow-z-index');
      }
    }

    // 檢查 2021 新方案是否要顯示 Chat plugin
    if (hideChatPluginFor2021NewPlan) {
      ecFloatBtn.classList.remove('easychat-float-botton-show');
      ecFloatBtn.classList.add('easychat-float-botton-hide');
    }

  } else if (data['ready-to-auth'] != null) {
    // console.log('chat > receive ready to auth');
    passTokenToIframeForAuth();
  } else if (data['ott-refresh'] != null) {
    var value = data['ott-refresh'];
    if (value === "easychat-ott-refresh") {
      location.reload();
    }
  } else if (data['close-btn-pressed'] != null) {
    var value = data['close-btn-pressed'];
    if (value === true) {
      handleCloseBtnPressed()
    }
  }
}

if (document.readyState === 'complete') {
  // console.log('chat > doc complete');
  easychatPluginEventInit();
} else if (window.attachEvent) {
  // console.log('chat > attachEvent onload');
  window.attachEvent('onload', easychatPluginEventInit);
} else {
  // console.log('chat > add event listener onload');
  window.addEventListener('load', easychatPluginEventInit, false);
}

window.addEventListener('message', handleChildMessage, false);

init();

if (utils.getCookie(EC_PIXEL_CONFIG_COOKIE)) {
  try {
    let config = JSON.parse(utils.atou(utils.getCookie(EC_PIXEL_CONFIG_COOKIE)));

    if (ecPartner !== '91App') {
      initTrackingPixel(config);
    }
  } catch (e) {
    console.log(e);
  }
}

function initTrackingPixel(config) {

  var trackerName = 'omnichatTracker';
  var tracker = window[trackerName] || [];

  // return if the setup has already occurred
  // this is to prevent double loading pixel.js if someone accidentally had this more than once on a page
  if (tracker && tracker.process) return;

  // tracker is an array before setup
  // store the events to config for later process after setup
  config.queue = tracker;

  setupPixel(config);

}

function handleCloseBtnPressed () {
  toggleNewOmnichatIcon();
  var ecCloseBtn = document.getElementById("easychat-close-btn");
  var ecChatDialog = document.getElementById("easychat-chat-dialog");

  ecCloseBtn.classList.remove("easychat-close-btn-show");
  ecChatDialog.classList.remove("easychat-chat-dialog-open");

  var unReadSpan = document.getElementById("easychat-unread-badge");
  if (unReadSpan == null) {
    unReadSpan = document.getElementById("easychat-unread-badge-left");
  }

  if (utils.isMobileDevice()) {
    utils.enableScroll();
    utils.removeClass(document.body, "prevent-scroll");
    document.getElementById('easychat-chat-dialog-iframe').scrolling = 'no';
    //  document.body.style.overflow = 'scroll';
    enableParentScroll = true;

    if (badge > 0) {
      unReadSpan.style.display = 'block';
    } else {
      unReadSpan.style.display = 'none';
    }

    var iframe = document.getElementById('easychat-chat-dialog-iframe');
    iframe.contentWindow.postMessage(JSON.stringify({ 'easychat-chat-dialog-status': "closed" }), EASYCHAT_CONFIG.IFRAME_HTML_SOURCE);
  }
}

/*
 * 這裡可以加入是否在客戶頁面上已經有 FB SDK 的檢查，避免衝突後影響客戶的 fb login 功能
 * 若有其他客戶有遇到衝突的話，可以將檢查加到這個 function
 */
function hasFacebookSDKConflict(ecPartner) {
  if (ecPartner === '91App' && location.href.includes('/V2/Login')) return true;
}