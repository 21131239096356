var Constants = {
  supportedCmds: ['init', 'event', 'send'],
  supportedActions: {
  	'init': ['config'],
  	'event': ['view_product', 'add_to_cart', 'remove_from_cart', 'checkout',
  		'purchase', '91app_member_mapping', 'member_mapping'
		],
		'send': ['pageview']
  },
  ecommerceEvents: ['view_product', 'add_to_cart', 'remove_from_cart', 'checkout', 'purchase'],
  cookie: {
  	utm: '__ocutm',
  	session: '__ocssid',
    saleId: '__ocsaid',
    trackId: '__octid',
    memberMapping: '__ocmm',
    documentLocation: '__ocdl'
  },
  params: {
    saleId: 'ocsaid',
    trackId: 'octid'
  }
}

export default Constants;
