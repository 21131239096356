import EASYCHAT_CONFIG from './config/config';
import * as utils from './utils';
import './helpers/qrcode';
import whatsappIconSrc from './images/whatsapp_logo.png';
import whatsappWhiteIconSrc from './images/whatsapp_logo_with_white_border.png';
import lineIconSrc from './images/line_logo.png';
import lineWhiteIconSrc from './images/line_logo_with_white_border.png'
import fbWhiteIconSrc from './images/facebook-no-border.svg'

function SocialSubscriber(socialSubscriber, appLang) {
  this.channelType = socialSubscriber.channel;
  this.channelId = socialSubscriber.channelId;
  this.currentUserName = socialSubscriber.currentUserName;
  this.ecOrderIdForSubscriber = socialSubscriber.ecOrderIdForSubscriber;
  this.lineAtId = socialSubscriber.lineAtId;
  this.whatsappNumber = socialSubscriber.whatsappAccTel ? socialSubscriber.whatsappAccTel.replace('+', '') : null;
  this.optinMessageType = socialSubscriber.optinMessageType;
  this.optinMessage = socialSubscriber.optinMessage;
  this.teamName = socialSubscriber.teamName;
  this.title = socialSubscriber.title;
  this.description = socialSubscriber.description;
  this.widgetType = socialSubscriber.widgetType;
  this.ecMemberId = socialSubscriber.ecMemberId;
  this.fbAppId = EASYCHAT_CONFIG.SOCIAL_SUBSCRIBER_APP_ID;
  this.appLang = appLang;
  this.isMobileView = socialSubscriber.isMobileView;
  this.expireTime = socialSubscriber.expireTime;
  this.liffId = socialSubscriber.bindingLiffId;
  this.buttonText = socialSubscriber.btnText;

  this.CONSTANT = {
    CHANNEL_TYPE: {
      FACEBOOK: 'fb',
      LINE: 'line',
      WHATSAPP: 'whatsapp'
    },
    WIDGET_TYPE: {
      GENERAL_SUBSCRIBER: 1,
      ORDER_LINK: 2
    },
    LANG: {
      EN: 'en',
      ZH_HANT: 'zh-Hant'
    },
    TEXT: {
      GENERAL_SUBSCRIBER_LINE_BUTTON_TEXT: 'Link LINE account',
      GENERAL_SUBSCRIBER_WHATSAPP_BUTTON_TEXT: 'Link WhatsApp',
      ORDER_LINK_LINE_BUTTON_TEXT: 'Link order',
      ENTER_LINE_CHAT_ROOM_INPUT_DIGIT_TITLE: 'Click here and input <span style="text-decoration: underline">{{sixDigit}}</span> to enter chat room.',
      ENTER_LINE_CHAT_ROOM_INPUT_DIGIT_TITLE_MOBILE: 'Click here and input <span style="text-decoration: underline">{{sixDigit}}</span> in chat room.',
      ENTER_LINE_CHAT_ROOM_INPUT_DIGIT_TITLE_LIFF: 'Click here to bind',
      SCAN_QRCODE: 'Scan QRcode and Send six digit number in LINE chat room',
      OPEN_WHATSAPP: 'Open WhatsApp',
      SCAN_QRCODE_LIFF: "Please Scan QRcode"
    }
  }

  this.setLanguageText();
  this.initSocialSubscriberHTML();
}

SocialSubscriber.prototype.setLanguageText = function () {
  if (this.appLang === this.CONSTANT.LANG.EN) {
    this.CONSTANT.TEXT = {
      GENERAL_SUBSCRIBER_LINE_BUTTON_TEXT: 'Link LINE account',
      GENERAL_SUBSCRIBER_WHATSAPP_BUTTON_TEXT: 'Link WhatsApp',
      ORDER_LINK_LINE_BUTTON_TEXT: 'Link order',
      ENTER_LINE_CHAT_ROOM_INPUT_DIGIT_TITLE: 'Click here and input <span style="text-decoration: underline">{{sixDigit}}</span> to enter chat room.',
      ENTER_LINE_CHAT_ROOM_INPUT_DIGIT_TITLE_MOBILE: 'Click here and input <span style="text-decoration: underline">{{sixDigit}}</span> in chat room.',
      ENTER_LINE_CHAT_ROOM_INPUT_DIGIT_TITLE_LIFF: 'Click here to bind',
      SCAN_QRCODE: 'Scan QRcode and Send six digit number in chat room',
      OPEN_WHATSAPP: 'Open WhatsApp',
      SCAN_QRCODE_LIFF: "Please Scan QRcode",
      SUBSCRIBE_IN_MESSENGER: "Subscribe in Messenger"
    }
  } else if (this.appLang === this.CONSTANT.LANG.ZH_HANT) {
    this.CONSTANT.TEXT = {
      GENERAL_SUBSCRIBER_LINE_BUTTON_TEXT: '連結 LINE 帳號',
      GENERAL_SUBSCRIBER_WHATSAPP_BUTTON_TEXT: '連結 WhatsApp 帳號',
      ORDER_LINK_LINE_BUTTON_TEXT: '連結訂單',
      ENTER_LINE_CHAT_ROOM_INPUT_DIGIT_TITLE: '按此進入，並輸入 <span style="text-decoration: underline">{{sixDigit}}</span> 以完成綁定',
      ENTER_LINE_CHAT_ROOM_INPUT_DIGIT_TITLE_MOBILE: '按此進入，並輸入 <span style="text-decoration: underline">{{sixDigit}}</span> 以完成綁定',
      ENTER_LINE_CHAT_ROOM_INPUT_DIGIT_TITLE_LIFF: '按此進入以完成綁定',
      SCAN_QRCODE: '請掃描 QRCode <br>並在聊天室傳送綁定號碼',
      OPEN_WHATSAPP: '打開 WhatsApp',
      SCAN_QRCODE_LIFF: "請掃描 QRCode",
      SUBSCRIBE_IN_MESSENGER: "透過 Messenger 訂閱"
    }
  }
}

SocialSubscriber.prototype.initSocialSubscriberHTML = function () {
  this.prependSubscriberHtml(this.renderSocialSubscriberBlock());
  this.addSubscriberEvent();

  if (this.isFacebookSubscriber()) {
    this.initFacebookSDK();
  } else if (this.isLINESubscriber()) {
    this.initLineSubscriberEvent();
  } else {
    this.initWhatsAppSubscriberEvent();
  }

  if (this.expireTime > 0) {
    this.autoDismissSubscriberTimeout()
  }
}

SocialSubscriber.prototype.prependSubscriberHtml = function (subscriberHTML) {
  document.querySelector('.easychat-chat').prepend(utils.createElementFromHTMLString(subscriberHTML));
}

SocialSubscriber.prototype.addSubscriberEvent = function () {
  let that = this;
  
  // close subscriber event
  document.getElementById('easychat-chat-dismiss-social-subscriber')
      .addEventListener('click', function () {
        let blockHTML = document.getElementById('social-subscriber-block');
        blockHTML.classList.remove('easychat-block-show');
        blockHTML.classList.add('easychat-block-hidden');

        // 如果是 general social subscriber，使用者按了叉後關閉要紀錄在 cookie 中
        if (that.widgetType === that.CONSTANT.WIDGET_TYPE.GENERAL_SUBSCRIBER) {
          utils.setCookie('is_general_subscriber_closed', true, 1);
        }
      });
}

SocialSubscriber.prototype.isFacebookSubscriber = function () {
  return this.channelType === this.CONSTANT.CHANNEL_TYPE.FACEBOOK;
}

SocialSubscriber.prototype.isLINESubscriber = function () {
  return this.channelType === this.CONSTANT.CHANNEL_TYPE.LINE;
}

SocialSubscriber.prototype.isWhatsAppSubscriber = function () {
  return this.channelType === this.CONSTANT.CHANNEL_TYPE.WHATSAPP;
}

SocialSubscriber.prototype.renderSocialSubscriberBlock = function () {
  // let socialPluginHTML = this.isFacebookSubscriber() ? this.renderFacebookSubscriber() : this.renderLineSubscriber();
  let socialPluginHTML = '';

  if (this.isFacebookSubscriber()) {
    socialPluginHTML = this.renderFacebookSubscriber();
  } else if (this.isLINESubscriber()) {
    socialPluginHTML = this.renderLineSubscriber();
  } else {
    // WhatsApp
    socialPluginHTML = this.renderWhatsAppSubscriber();
  }

  let isMobileViewOn = this.isMobileView && utils.isMobileDevice()
  let socialSubscriberPosition = '', socialSubscriberDesc = '', mobileSocialSubscriberAuthor = '';
  
  if (isMobileViewOn) {
    mobileSocialSubscriberAuthor = 'id="subscriber-title-mobile"';
    socialSubscriberPosition = document.getElementById("easychat-floating-button") ? 'social-subscriber-position-right' : 'social-subscriber-position-left';
  } else {
    socialSubscriberDesc = `
    <div class="easychat-blocks">
      <div class="easychat-block easychat-block-paragraph" id="easychat-block-message-id" style="margin-bottom: 0; word-break: break-word; white-space: pre-line;">${ this.description }</div>
    </div>
  `
  }
    
  if (isMobileViewOn && this.isFacebookSubscriber()) {
    mobileSocialSubscriberAuthor = `style="padding:15px 20px 5px;"`;
  }

  return `
    <div id="social-subscriber-block" class="easychat-block-show">
      <div class="easychat-chat-card" id=${socialSubscriberPosition}>
        <div class="easychat-chat-card-avatar easychat-chat-card-avatar-team">
          <div class="easychat-team-avatar-container">
          </div>
        </div>
      <div>
      <div class="easychat-chat-card-author" ${ mobileSocialSubscriberAuthor }>
        <div class="easychat-author-summary-name-from ${isMobileViewOn ? 'mobile': ''}" >
          <span class="easychat-author-summary-name" style=${ utils.isMobileDevice() && this.isMobileView ? "font-size:inherit;" : "" }>${ this.title }</span>
        </div>
      </div>
      <div class="easychat-chat-card-body">
        <div class="easychat-scrollable">
          ${ socialSubscriberDesc }
          ${ socialPluginHTML }
        </div>
      </div>
      <div class="easychat-chat-dismiss-button-mobile ${isMobileViewOn ? 'mobile': ''}" id="easychat-chat-dismiss-social-subscriber">&nbsp;</div>
    </div>
  `
}

SocialSubscriber.prototype.renderFacebookSubscriber = function () {
  let cta_text = '';
  let mobileFbStyle = this.isMobileView && utils.isMobileDevice() ? 'style="padding:0"' : '';

  switch (this.widgetType) {
    case this.CONSTANT.WIDGET_TYPE.GENERAL_SUBSCRIBER:
      cta_text = 'SUBSCRIBE_IN_MESSENGER';
      break;
    case this.CONSTANT.WIDGET_TYPE.ORDER_LINK:
      cta_text = 'ALERT_ME_IN_MESSENGER';
      break;
  }
  
  let buttonLabel = this.CONSTANT.TEXT.SUBSCRIBE_IN_MESSENGER;

  let dataRef = utils.b64EncodeUnicode(JSON.stringify({
    teamName: this.teamName, // from chat iframe
    orderId: this.widgetType === this.CONSTANT.WIDGET_TYPE.ORDER_LINK ? this.ecOrderIdForSubscriber : '', // from page HTML
    memberId: this.ecMemberId || '',
    easychatUserId: this.currentUserName,
    optinMessage: this.optinMessage,
    optinMessageType: this.optinMessageType,
    optinType: 'social_sub'
  }));

  return `
    <div class="fb-optin-plugin">
      <div class="fb-m-me">
        <a class="fb-m-me-btn" href="https://m.me/${this.channelId}?ref=${dataRef}" target="_blank">
          <div class="flex-block">
            <img src="${fbWhiteIconSrc}" alt="" class="fb-btn-logo" />
            ${buttonLabel}
          </div>
        </a>
      </div>
    </div>
  `

  // return `
  //   <div class="fb-send-to-messenger-wrapper">
  //    <div class="fb-send-to-messenger"
  //         messenger_app_id="${this.fbAppId}"
  //         page_id="${this.channelId}"
  //         data-ref="${dataRef}"
  //         color="blue"
  //         cta_text="${cta_text}"
  //         size="standard"
  //         ${mobileFbStyle} 
  //    </div>
  //  </div>  
  // `
}

SocialSubscriber.prototype.renderLineSubscriber = function () {
  let buttonText = this.buttonText || '';
  let isMobileViewOn = this.isMobileView && utils.isMobileDevice();
  let mobileLineStyle = isMobileViewOn ? 'style="margin:0 0 10px; width:100%"' : '';

  switch (this.widgetType) {
    case this.CONSTANT.WIDGET_TYPE.GENERAL_SUBSCRIBER:
      buttonText = buttonText || this.CONSTANT.TEXT.GENERAL_SUBSCRIBER_LINE_BUTTON_TEXT;
      break;
    case this.CONSTANT.WIDGET_TYPE.ORDER_LINK:
      buttonText = buttonText || this.CONSTANT.TEXT.ORDER_LINK_LINE_BUTTON_TEXT;
      break;
  }

  return `
    <div class="line-social-widget-wrapper">
      <button id="social-subscriber-link-line-button" class="line-button" ${mobileLineStyle}>
        <img src="${lineIconSrc}" alt="" class="line-logo">
        <div style="text-align: left;">
          ${ buttonText }
        </div>
      </button>
      <div id="add-line-friend-block" style="display: none;"></div>
      <div class="social-subscriber-qrcode-wrapper">
        <div id="social-subscriber-qrcode"></div>
        <div class="line-optin-instruction">${ this.liffId? this.CONSTANT.TEXT.SCAN_QRCODE_LIFF : this.CONSTANT.TEXT.SCAN_QRCODE }</div>
        <div id="social-subscriber-line-six-digit" style="margin-bottom: 8px;"></div>
      </div>
    </div>
  `
}

SocialSubscriber.prototype.renderWhatsAppSubscriber = function () {
  let buttonText = this.buttonText || this.CONSTANT.TEXT.GENERAL_SUBSCRIBER_WHATSAPP_BUTTON_TEXT;
  let isMobileViewOn = this.isMobileView && utils.isMobileDevice();
  let mobileLineStyle = isMobileViewOn ? 'style="margin:0 0 10px; width:100%"' : '';

  return `
    <div class="whatsapp-social-widget-wrapper">
      <button id="social-subscriber-link-whatsapp-button" class="whatsapp-button" ${mobileLineStyle}>
        <img src="https://media-cdn.omnichat.ai/upload/photos/chat-plugin-img/whatsapp_logo.png" alt="" class="whatsapp-logo">
        <div style="text-align: left;">
          ${ buttonText }
        </div>
      </button>
      <div id="add-whatsapp-block" style="display: none"></div>
      <div class="social-subscriber-qrcode-wrapper">
        <div id="social-subscriber-qrcode"></div>
        <a class="whatsapp-qrcode-button" style="display: none;" target="_blank">
          ${ this.CONSTANT.TEXT.OPEN_WHATSAPP }
        </a>
        <div class="whatsapp-optin-instruction">${ this.CONSTANT.TEXT.SCAN_QRCODE }</div>
        <div id="social-subscriber-whatsapp-six-digit" style="margin-bottom: 8px;"></div>
      </div>
    </div>
  `
}

SocialSubscriber.prototype.initFacebookSDK = function () {
  // console.log('init fb sdk')
  const that = this
  let sdkLang = 'en_US';

  switch (this.appLang) {
    case this.CONSTANT.LANG.EN:
      sdkLang = 'en_US';
      break;
    case this.CONSTANT.LANG.ZH_HANT:
      sdkLang = 'zh_TW';
  }
  if (typeof window.FB !== 'undefined') {
    FB.init({
      appId      : this.fbAppId,
      cookie     : true,  // enable cookies to allow the server to access
                          // the session
      xfbml      : true,  // parse social plugins on this page
      version    : 'v18.0'
    })
  } else {
    // console.log('inside else')
    window.fbAsyncInit = function() {
      // console.log('inside fbasyncinit')
      FB.init({
        appId      : that.fbAppId,
        cookie     : true,  // enable cookies to allow the server to access
                            // the session
        xfbml      : true,  // parse social plugins on this page
        version    : 'v18.0'
      });
    };

    // Load the SDK asynchronously
    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/" + sdkLang + "/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

  }
}

SocialSubscriber.prototype.initLineSubscriberEvent = function () {
  let iframe = document.getElementById('easychat-chat-dialog-iframe');
  let that = this;

  document.getElementById('social-subscriber-link-line-button').addEventListener('click', function () {
    let payload = {
      'get_line_link_number_request': {
        orderId: that.widgetType === that.CONSTANT.WIDGET_TYPE.ORDER_LINK ? that.ecOrderIdForSubscriber : '',
        memberId: that.ecMemberId || '',
        teamName: that.teamName,
        optinMessageType: that.optinMessageType,
        optinMessage: that.optinMessage,
        optinType: 'social_sub'
      }
    };
    iframe.contentWindow.postMessage(JSON.stringify(payload), '*');
  });

  window.addEventListener('message', function (event) {
    if (event.origin !== EASYCHAT_CONFIG.IFRAME_HTML_SOURCE) {
      return; 
    }
    let data = '';
    try {
      data = JSON.parse( event.data );
    } catch (e) {
      return false;
    }

    if(data['get_line_link_number_response']) {
      // 避免 abandoned cart optin modal 又重複出現 LINE 綁定的按鈕
      window.alreadyGetLineSixDigit = true;

      let sixDigit = data['get_line_link_number_response'];
      let lineAddFriendLink = `https://line.me/R/oaMessage/@${that.lineAtId}/?${sixDigit}`
      let mobileAddLineFriendButton = '';
      let enterChatRoomMsg = that.isMobileView && utils.isMobileDevice() ? that.CONSTANT.TEXT.ENTER_LINE_CHAT_ROOM_INPUT_DIGIT_TITLE_MOBILE : that.CONSTANT.TEXT.ENTER_LINE_CHAT_ROOM_INPUT_DIGIT_TITLE;
      if(that.liffId) {
        const orderId = that.widgetType === that.CONSTANT.WIDGET_TYPE.ORDER_LINK ? that.ecOrderIdForSubscriber : null;
        lineAddFriendLink = `https://liff.line.me/${that.liffId}/?liffId=${that.liffId}&ecId=${that.currentUserName}&channelId=${that.channelId}&memberId=${that.ecMemberId}&oaId=${that.lineAtId}&optinType=social_sub&orderId=${orderId}`
        enterChatRoomMsg= that.CONSTANT.TEXT.ENTER_LINE_CHAT_ROOM_INPUT_DIGIT_TITLE_LIFF
      }

      if (that.isMobileView && utils.isMobileDevice()) {
        document.querySelector('.line-social-widget-wrapper').style.paddingTop = '10px'
        mobileAddLineFriendButton = document.getElementById("easychat-floating-button") ? 'id="add-line-friend-button-mobile"' : 'id="add-line-friend-button-mobile-left"';
        document.getElementById('subscriber-title-mobile').style.display = 'none';

        document.getElementById('add-line-friend-block').style.display = 'block';
        document.getElementById('add-line-friend-block').innerHTML = '<a class="line-button" href="' + lineAddFriendLink + '"' + mobileAddLineFriendButton + '>' + enterChatRoomMsg.replace('{{sixDigit}}', sixDigit) + '</a>'
      } else if (utils.isMobileDevice() && !that.isMobileView) {
        mobileAddLineFriendButton = 'id="add-line-friend-button"';
        
        document.getElementById('add-line-friend-block').style.display = 'block';
        document.getElementById('add-line-friend-block').innerHTML = '<a class="line-button" href="' + lineAddFriendLink + '"' + mobileAddLineFriendButton + '>' + enterChatRoomMsg.replace('{{sixDigit}}', sixDigit) + '</a>'
      }
      else {
        // desktop version
        // qrcode
        let qrCode = new OMNI_QRCODE('social-subscriber-qrcode', {
          width: 128 * 5,
          height: 128 * 5,
          correctLevel : OMNI_QRCODE.CorrectLevel.Q
        });
        qrCode.makeCode(lineAddFriendLink);
        qrCode.addLogo('social-subscriber-qrcode', lineWhiteIconSrc);

        document.querySelector('.social-subscriber-qrcode-wrapper .line-optin-instruction').style.display = 'block';
        if(!that.liffId) document.querySelector('.social-subscriber-qrcode-wrapper #social-subscriber-line-six-digit').innerHTML = sixDigit;
        document.querySelector('.social-subscriber-qrcode-wrapper #social-subscriber-line-six-digit').style.display = 'block';
        document.getElementById('social-subscriber-qrcode').style.display = 'flex';
      }
         
      document.getElementById('social-subscriber-link-line-button').style.display = 'none';
    }
  },false)
}

SocialSubscriber.prototype.initWhatsAppSubscriberEvent = function () {
  let iframe = document.getElementById('easychat-chat-dialog-iframe');
  let that = this;

  document.getElementById('social-subscriber-link-whatsapp-button').addEventListener('click', function () {
    let payload = {
      'get_whatsapp_link_number_request': {
        orderId: that.widgetType === that.CONSTANT.WIDGET_TYPE.ORDER_LINK ? that.ecOrderIdForSubscriber : '',
        memberId: that.ecMemberId || '',
        teamName: that.teamName,
        optinMessage: that.optinMessage,
        optinMessageType: that.optinMessageType,
        optinType: 'social_sub'
      }
    }
    iframe.contentWindow.postMessage(JSON.stringify(payload), '*');
  });

  window.addEventListener('message', function (event) {
    if (event.origin !== EASYCHAT_CONFIG.IFRAME_HTML_SOURCE) {
      return; 
    }
    let data = '';
    try {
      data = JSON.parse( event.data );
    } catch (e) {
      return false;
    }

    if (data['get_whatsapp_link_number_response']) {
      window.alreadyGetWhatsappSixDigit = true;
      that.handleWhatsAppSixDigitResponse(data['get_whatsapp_link_number_response']);
    }
  });
}

SocialSubscriber.prototype.handleWhatsAppSixDigitResponse = function (sixDigit) {
  // desktop version
  let url = `https://wa.me/${this.whatsappNumber}?text=${sixDigit}`;
  let addWhatsAppBlock = document.getElementById('add-whatsapp-block');
  let mobileAddWhatsAppButton = '';
  let enterChatRoomMsg = this.isMobileView && utils.isMobileDevice() ? this.CONSTANT.TEXT.ENTER_LINE_CHAT_ROOM_INPUT_DIGIT_TITLE_MOBILE : this.CONSTANT.TEXT.ENTER_LINE_CHAT_ROOM_INPUT_DIGIT_TITLE;

  if (utils.isMobileDevice() && this.isMobileView) {
    mobileAddWhatsAppButton = document.getElementById("easychat-floating-button") ? 'id="add-whatsapp-button-mobile"' : 'id="add-whatsapp-button-mobile-left"';
    document.getElementById('subscriber-title-mobile').style.display = 'none';

    addWhatsAppBlock.style.display = 'block';
    addWhatsAppBlock.innerHTML = '<a class="add-whatsapp-button whatsapp-button" href="' + url + '"' + mobileAddWhatsAppButton + '>' + enterChatRoomMsg.replace('{{sixDigit}}', sixDigit) + '</a>'
  } else if (utils.isMobileDevice() && !this.isMobileView) {
    addWhatsAppBlock.style.display = 'block';
    addWhatsAppBlock.innerHTML = '<a class="add-whatsapp-button whatsapp-button" href="' + url + '"' + '' + '>' + enterChatRoomMsg.replace('{{sixDigit}}', sixDigit) + '</a>'
  } else {
    let qrCode = new OMNI_QRCODE('social-subscriber-qrcode', {
      width: 128 * 5,
      height: 128 * 5,
      correctLevel : OMNI_QRCODE.CorrectLevel.Q
    });

    qrCode.makeCode(url);
    qrCode.addLogo('social-subscriber-qrcode', 'https://media-cdn.omnichat.ai/upload/photos/chat-plugin-img/whatsapp_logo_with_white_border.png')

    let qrCodeWrapper = document.getElementById('social-subscriber-qrcode');
    qrCodeWrapper.style.display = 'flex';
    document.querySelector('.social-subscriber-qrcode-wrapper .whatsapp-optin-instruction').style.display = 'block';
    document.querySelector('.social-subscriber-qrcode-wrapper #social-subscriber-whatsapp-six-digit').innerHTML = sixDigit;
    document.querySelector('.social-subscriber-qrcode-wrapper #social-subscriber-whatsapp-six-digit').style.display = 'block';
    document.getElementById('social-subscriber-link-whatsapp-button').style.display = 'none';
    document.querySelector('.whatsapp-qrcode-button').setAttribute('href', url);
    document.querySelector('.whatsapp-qrcode-button').style.display = 'block';
  }

  document.getElementById('social-subscriber-link-whatsapp-button').style.display = 'none';
}

SocialSubscriber.prototype.autoDismissSubscriberTimeout = function () {
  setTimeout(() => {
    let el = document.getElementById('social-subscriber-block');
    if (el) {
      el.className = 'easychat-block-hidden'
    }
  }, this.expireTime*1000);
}

export default SocialSubscriber;