//http://www.w3schools.com/js/js_cookies.asp
import helpers from './helpers';
import Cookie from './cookie';
import Config from './config';
import Constants from './constants';
import EASYCHAT_CONFIG from '../config/config';

var Session = {
  prepareSession() {

    var sessionExists = Cookie.exists(Constants.cookie.session);
    var now = helpers.now();
    var sessionInfo = [];

    if (sessionExists) {
      var currentSession = Cookie.get(Constants.cookie.session);

      var currentSessionInfo = currentSession.split('.');

      if (currentSessionInfo.length === 3) {
        var sessionId = currentSessionInfo[0];
        var start = parseInt(currentSessionInfo[1]);
        var lastActive = parseInt(currentSessionInfo[2]);

        /* session is still active (less than 30 mins of inactivity) */
        if (now - lastActive < 60 * 1000 * EASYCHAT_CONFIG.SESSION_LIFETIME) {
          // renew the last active timestamp
          sessionInfo.push(sessionId);
          sessionInfo.push(start);
          sessionInfo.push(now);
        }

        /* reset session if the session is across midnight **/
        var lastActiveDate = new Date(lastActive);
        var nowDate = new Date(now);
        if (lastActiveDate.getDate() != nowDate.getDate()) {
          sessionInfo = [];
        }

      }
    }

    if (sessionInfo.length !== 3) {
      sessionInfo = [];
      sessionInfo.push(helpers.guid());
      sessionInfo.push(now);
      sessionInfo.push(now);

      if (!Config.utmInUrl) {
        // only delete utm data when session start/expired
        // and the utm is not in the current url
        Cookie.delete(Constants.cookie.utm);
      }
    }

    var session = sessionInfo.join('.');
    Cookie.set(Constants.cookie.session, session, EASYCHAT_CONFIG.SESSION_LIFETIME);
  },

  getSession() {
    return Cookie.get(Constants.cookie.session);
  }


}

export default Session;