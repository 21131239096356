let EASYCHAT_CONFIG = {};


// EASYCHAT_ENV is defined by webpack DefinePlugin, see in webpack.config.js
if (EASYCHAT_ENV === 'DOCKER') {
  EASYCHAT_CONFIG = {
    IFRAME_HTML_SOURCE: 'https://local-chat-cs.easychat.co',
    SOCIAL_SUBSCRIBER_APP_ID: '1631146543678812',
    TRACKING_PIXEL_ENDPOINT: 'https://local-api.easychat.co/restapi/v1/pixel/track',
    SESSION_LIFETIME: 30,
    TRACK_ID_LIFETIME: 365
  }
} else if (EASYCHAT_ENV === 'LOCAL') {
  EASYCHAT_CONFIG = {
    // IFRAME_HTML_SOURCE: 'http://chat-cs.demo.local',
    IFRAME_HTML_SOURCE: 'http://localhost:5501',
    SOCIAL_SUBSCRIBER_APP_ID: '1631146543678812',
    TRACKING_PIXEL_ENDPOINT: 'https://local-api.easychat.co/restapi/v1/pixel/track',
    SESSION_LIFETIME: 30,
    TRACK_ID_LIFETIME: 365
  }
} else if (EASYCHAT_ENV === 'UAT') {
  EASYCHAT_CONFIG = {
    IFRAME_HTML_SOURCE: 'https://uat-chat-plugin.easychat.co',
    SOCIAL_SUBSCRIBER_APP_ID: '1631146543678812',
    TRACKING_PIXEL_ENDPOINT: 'https://uat-track.omnichat.ai/restapi/v1/pixel/track',
    SESSION_LIFETIME: 30,
    TRACK_ID_LIFETIME: 365
  }
} else if (EASYCHAT_ENV === 'STAGING') {
  EASYCHAT_CONFIG = {
    IFRAME_HTML_SOURCE: 'https://staging-client-chat.omnichat.ai',
    SOCIAL_SUBSCRIBER_APP_ID: '1631146543678812',
    TRACKING_PIXEL_ENDPOINT: 'https://staging-track.omnichat.ai/restapi/v1/pixel/track',
    SESSION_LIFETIME: 30,
    TRACK_ID_LIFETIME: 365
  }
}
else if (EASYCHAT_ENV === 'PROD') {
  EASYCHAT_CONFIG = {
    IFRAME_HTML_SOURCE: 'https://client-chat.easychat.co',
    SOCIAL_SUBSCRIBER_APP_ID: '1234733519968070',
    TRACKING_PIXEL_ENDPOINT: 'https://track.omnichat.ai/restapi/v1/pixel/track',
    SESSION_LIFETIME: 30,
    TRACK_ID_LIFETIME: 365
  }
}

let COMMON_CONFIG = {
  PARTNER_91APP: '91App',
  PARTNER_WACA: 'Waca'
}

Object.assign(EASYCHAT_CONFIG, COMMON_CONFIG);

export default EASYCHAT_CONFIG;


