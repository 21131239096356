//http://www.w3schools.com/js/js_cookies.asp
import helpers from './helpers';
import Constants from './constants';
import Url from './url';
import Config from './config';
import EASYCHAT_CONFIG from '../config/config';

var Cookie = {

  prefix() {
    return  '';
  },

  getRootDomain(url) {
    var hostname;
    
    // Find hostname in the URL
    if (url.indexOf("://") > -1) {
      hostname = url.split('/')[2];
    } else {
      hostname = url.split('/')[0];
    }

    if (hostname.indexOf("localhost") !== -1) {
      return "localhost";
    }
    
    // Find the part after the last dot
    var hostParts = hostname.split('.');
    var hostPartLength = hostParts.length;

    var rootDomain
    if(hostPartLength > 2) {
        rootDomain = hostParts[hostPartLength - 3] + '.' + hostParts[hostPartLength - 2] + '.' + hostParts[hostPartLength - 1];
    } else {
        rootDomain = hostParts[hostPartLength - 2] + '.' + hostParts[hostPartLength - 1];
    }
    
    return rootDomain;
  },

  set(name, value, minutes, path = "/") {
    // 針對美麗華旅遊 / Gomaji 設定 root domain
    const domainMap = {
      'hkmiramartravel.com': '; domain=hkmiramartravel.com',
      'gomaji.com': '; domain=gomaji.com'
    };
    const hostname = window.location.hostname;
    const domainSuffix = Object.keys(domainMap).find(domain => 
      hostname.includes(domain));
    const domain = domainSuffix ? domainMap[domainSuffix] : '';

    var expires = "";
    if (helpers.isset(minutes)) {
      var date = new Date();
      date.setTime(date.getTime()+(minutes*60*1000));
      expires = "; expires="+date.toGMTString();
    }

    // encode cookie value, because safari did not accept non ascii character
    value = encodeURIComponent(value);

    document.cookie = this.prefix() + name + "=" + value + expires + "; path=" + path + domain;
  },

  get(name) {
    var name = this.prefix() + name + "=";
    var ca = document.cookie.split(';');
    for (var i=0; i<ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1);
        if (c.indexOf(name) == 0) return decodeURIComponent(c.substring(name.length,c.length));
    }
    return;
  },

  delete(name) {
    this.set(name,"",-100);
  },

  exists(name) {
    return helpers.isset(this.get(name));
  },

  // set a cookie that expires in 10 minutes to throttle analytics requests from that page
  // throttle(name){
  //   this.set(name, 1, 10, window.location.pathname);
  // },

  setUtms() {

    var utmArray = ['utm_source','utm_medium','utm_term','utm_content','utm_campaign'];
    var utmExists = false;
    var utm = {};
    /** check if gclid / fbclid exists **/
    if (helpers.isset(Url.getParameterByName('gclid'))) {
      utmExists = true;
      utm.utm_source = 'google';
      utm.utm_medium = 'cpc';
      utm.utm_campaign = 'acquisition';
    }

    if (!utmExists) {

      var utmQueryExists = false;

      for (var i = 0, l = utmArray.length; i < l; i++) {
        if (helpers.isset(Url.getParameterByName(utmArray[i]))) {
          utmQueryExists = true;
          utmExists = true;
          break;
        }
      }

      if (utmQueryExists) {
        var val = {};
        for (var i = 0, l = utmArray.length; i < l; i++) {
          val = Url.getParameterByName(utmArray[i]);
          if (helpers.isset(val)) {
            utm[utmArray[i]] = val;
          }
        }
      }

    }

    // get utm from referrer url
    if (!utmExists
      && helpers.isset(document.referrer)
      && document.referrer != window.location.href) {

      var utmQueryExists = false;

      for (var i = 0, l = utmArray.length; i < l; i++) {
        if (helpers.isset(Url.getParameterByName(utmArray[i], document.referrer))) {
          utmQueryExists = true;
          utmExists = true;
          break;
        }
      }

      if (utmQueryExists) {
        var val = {};
        for (var i = 0, l = utmArray.length; i < l; i++) {
          val = Url.getParameterByName(utmArray[i], document.referrer);
          if (helpers.isset(val)) {
            utm[utmArray[i]] = val;
          }
        }
      }

    }

    if (!utmExists) {
      var referrer = document.referrer;
      var referrerInfo = helpers.getPathInfo(referrer);

      var supportedReferrers = ['google.com', 'facebook.com', 'instagram.com'];

      for (var i = 0, l = supportedReferrers.length; i < l; i++) {
        if (referrerInfo.host.indexOf(supportedReferrers[i]) > -1) {
          utmExists = true;
          utm.utm_source = supportedReferrers[i].replace('.com', '');
          if (supportedReferrers[i] == 'google.com') {
            utm.utm_medium = 'organic';
          } else {
            utm.utm_medium = 'referral';
          }
          break;
        }
      }

    }

    if (utmExists) {

      Config.utmInUrl = true;

      var changed = false;
      /** compare the utm **/
      if (this.exists(Constants.cookie.utm)) {
        var prevUtm = {};
        try {
          prevUtm = JSON.parse(this.get(Constants.cookie.utm))
        } catch (e) {}

        for (var i = 0, l = utmArray.length; i < l; i++) {
          if (prevUtm[utmArray[i]] != utm[utmArray[i]]) {
            changed = true;
            break;
          }
        }
      } else {
        changed = true;
      }

      if (changed) {
        this.delete(Constants.cookie.session);
      }

      this.set(Constants.cookie.utm, JSON.stringify(utm), 2*365*24*60);
    }

  },

  getUtm(name) {
    if (this.exists(Constants.cookie.utm)) {
      var utms = {};
      try {
        utms = JSON.parse(this.get(Constants.cookie.utm));
      } catch (e) {}

      return name in utms ? utms[name] : "";
    }
  },

  setSaleId() {
    var saleId = false;
    if (helpers.isset(Url.getParameterByName(Constants.params.saleId))) {
      saleId = Url.getParameterByName(Constants.params.saleId);

      this.set(Constants.cookie.saleId, saleId, Config.salesIdLifetime*24*60);
    }

    // try to get saleId from referrer url
    if (!saleId
        && helpers.isset(document.referrer)
        && document.referrer != window.location.href) {

      saleId = Url.getParameterByName(Constants.params.saleId, document.referrer);

      if (helpers.isset(saleId)) {
        this.set(Constants.cookie.saleId, saleId, Config.salesIdLifetime*24*60);
      }

    }

  },

  setTrackId() {

    var trackId = false;

    if (helpers.isset(Url.getParameterByName(Constants.params.trackId))) {
      trackId = Url.getParameterByName(Constants.params.trackId);

      this.set(Constants.cookie.trackId, trackId, EASYCHAT_CONFIG.TRACK_ID_LIFETIME*24*60);
    }

    // try to get saleId from referrer url
    if (!trackId
        && helpers.isset(document.referrer)
        && document.referrer != window.location.href) {

      trackId = Url.getParameterByName(Constants.params.trackId, document.referrer);

      if (helpers.isset(trackId)) {
        this.set(Constants.cookie.trackId, trackId, EASYCHAT_CONFIG.TRACK_ID_LIFETIME*24*60);
      }
    }
  }
}

export default Cookie;