import Cookie from './cookie';

const AbandonedCart = {
  handleAddToCartEvent () {
    let addToCartTimes = Cookie.get('add_to_cart_times');
    addToCartTimes = addToCartTimes ? parseInt(addToCartTimes) + 1 : 1;
    Cookie.set('add_to_cart_times', addToCartTimes, 60 * 24);

    window.abandonedCartOptinEvent = new CustomEvent('show_cart_optin');
    window.dispatchEvent(window.abandonedCartOptinEvent);
    console.log('dispatch optin event')
  }
}

export default AbandonedCart;