var Browser = {
  nameAndVersion() {
    // http://stackoverflow.com/questions/5916900/how-can-you-detect-the-version-of-a-browser
    var ua= navigator.userAgent, tem,
    M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
      return 'IE '+(tem[1] || '');
    }
    if (M[1]=== 'Chrome') {
      tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
      if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
    if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
    return M.join(' ');
  },

  isMobile() {
    return 'ontouchstart' in document;
  },

  userAgent() {
    return window.navigator.userAgent;
  },

  deviceType() {

    var isMobile = this.isMobile();

    if (!isMobile) {
      return 'web_desktop';
    }

    var userAgent = window.navigator.userAgent || window.navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      return "web_android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "web_ios";
    }

    return "unknown";
  }
}

export default Browser;