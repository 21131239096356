var Config = {
  team: '',
  shopId: '',
  shopPlatform: '',
  memberId: '',
  deviceId: '',
  salesIdLifetime: 30,
  version: '1.0'
}

export default Config;
